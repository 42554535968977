const messages = {
   _default: (field) => `${field} er ikke gyldig.`,
   date_format: (field, [format]) =>
      `${field} skal være i formatet: ${format}.`,
   decimal: (field, [decimals = '*'] = []) =>
      `${field} skal være numerisk og må maksimalt indeholde${
         !decimals || decimals === '*' ? '' : ' ' + decimals
      } decimaler.`,
   digits: (field, [length]) => `${field} skal være et tal på ${length} cifre.`,
   email: (field) => `${field} skal være en gyldig email.`,
   min: (field, [length]) => `${field} skal minimum være ${length} karakterer.`,
   numeric: (field) => `${field} skal være numerisk.`,
   regex: (field) => `${field} skal have et gyldigt format.`,
   required: (field) => `${field} skal udfyldes.`,
}

Vue.use(VeeValidate, {
   locale: 'da',
   classes: true,
   dictionary: {
      da: { messages },
   },
})

if (document.getElementById('js-booking')) {
   var app = new Vue({
      el: '#js-booking',
      data: {
         loading: false,
         success: false,
         error: false,
         message: null,
         active: false,
         bookingId: null,
         registration: {
            Email: '',
            BookingsId: null,
            Buildings: {},
         },
      },

      computed: {
         hasBuildings() {
            return !!Object.keys(this.registration.Buildings).find(
               (x) => this.registration.Buildings[x] === true
            )
         },
      },

      mounted() {
         this.checkBuildings()
      },

      methods: {
         checkBuildings() {
            const preSelect = document.getElementById('Buildings2895')
            if (preSelect) {
               preSelect.click()
            }
         },
         submit(bookingsId) {
            this.registration.BookingsId = bookingsId

            this.$validator.validate().then((valid) => {
               if (!valid) {
                  var firstError = document.querySelector('.invalid')
                  var distance =
                     firstError.offsetTop -
                     firstError.scrollTop +
                     firstError.clientTop

                  document.getElementById('js-booking').scrollTo({
                     top: distance - 10,
                     behavior: 'smooth',
                  })
               } else {
                  this.loading = true

                  var xhr = new XMLHttpRequest()

                  xhr.onload = () => {
                     if (xhr.status >= 200 && xhr.status < 300) {
                        this.loading = false
                        this.success = true
                        this.registration = JSON.parse(xhr.response)
                     } else {
                        this.success = false
                        this.error = true
                        this.loading = false
                        this.message = xhr.response
                        console.log('Error')
                     }
                  }

                  xhr.open('POST', '/Umbraco/Api/Booking/RequestBooking')
                  xhr.setRequestHeader(
                     'Content-Type',
                     'application/json;charset=UTF-8'
                  )
                  xhr.send(JSON.stringify(this.registration))
               }
            })
         },
         openBooking() {
            this.active = true
            document
               .getElementsByTagName('body')[0]
               .classList.add('overlay-open')
            document
               .getElementsByTagName('html')[0]
               .classList.add('overlay-open')
         },
         closeBooking() {
            this.active = false
            document
               .getElementsByTagName('body')[0]
               .classList.remove('overlay-open')
            document
               .getElementsByTagName('html')[0]
               .classList.remove('overlay-open')
         },
      },
   })
}
