document.addEventListener('DOMContentLoaded', function () {
   var calendarEl = document.getElementById('calendar')

   if (calendarEl) {
      let contentHeight = 600

      var calendar = new FullCalendar.Calendar(calendarEl, {
         events: dates,
         locale: 'da',
         contentHeight,
         headerToolbar: {
            right: 'prevYear,prev,today,next,nextYear',
         },
         displayEventTime: false,
         displayEventEnd: true,
      })

      calendar.render()
   }
})
