const messages = {
   _default: (field) => `${field} er ikke gyldig.`,
   date_format: (field, [format]) =>
      `${field} skal være i formatet: ${format}.`,
   decimal: (field, [decimals = '*'] = []) =>
      `${field} skal være numerisk og må maksimalt indeholde${
         !decimals || decimals === '*' ? '' : ' ' + decimals
      } decimaler.`,
   digits: (field, [length]) => `${field} skal være et tal på ${length} cifre.`,
   email: (field) => `${field} skal være en gyldig email.`,
   min: (field, [length]) => `${field} skal minimum være ${length} karakterer.`,
   numeric: (field) => `${field} skal være numerisk.`,
   regex: (field) => `${field} skal have et gyldigt format.`,
   required: (field) => `${field} skal udfyldes.`,
}

Vue.use(VeeValidate, {
   locale: 'da',
   classes: true,
   dictionary: {
      da: { messages },
   },
})

if (document.getElementById('js-signup')) {
   var app = new Vue({
      el: '#js-signup',
      data() {
         return {
            loading: false,
            success: false,
            error: false,
            message: null,
            active: window.location.hash === '#signup',
            signUpId: null,
            addons: {},
            registration: {
               Addons: [],
               TotalPrice: parseInt(
                  document
                     .getElementById('js-signup')
                     .getAttribute('data-camp-price')
               ),
               IsOnWaitingList: false,
               Email: '',
               SignUpId: null,
               PhotoConsentDiasshow: false,
               PhotoConsentWebsite: false,
               PhotoConsentFacebook: false,
               PhotoConsentPress: false,
               PhotoConsentNone: !document.getElementById('PhotoConsent'),
            },
         }
      },
      computed: {
         noPhotoConsent() {
            return this.registration.PhotoConsentNone
         },
         givenPhotoConcent() {
            return (
               this.registration.PhotoConsentDiasshow ||
               this.registration.PhotoConsentWebsite ||
               this.registration.PhotoConsentFacebook ||
               this.registration.PhotoConsentPress
            )
         },
         consentValid() {
            return this.givenPhotoConcent || this.registration.PhotoConsentNone
         },
      },
      watch: {
         noPhotoConsent() {
            if (this.registration.PhotoConsentNone) {
               this.registration.PhotoConsentDiasshow = false
               this.registration.PhotoConsentWebsite = false
               this.registration.PhotoConsentFacebook = false
               this.registration.PhotoConsentPress = false
            }
         },
         givenPhotoConcent() {
            if (this.givenPhotoConcent) {
               this.registration.PhotoConsentNone = false
            }
         },
      },
      methods: {
         newSignUp() {
            window.location.replace(
               window.location.origin + window.location.pathname + '#signup'
            )
            window.location.reload()
         },
         submit(campId) {
            this.registration.CampId = campId
            this.registration.TotalPrice = parseInt(
               document
                  .getElementById('js-signup')
                  .getAttribute('data-camp-price')
            )

            if (this.registration.Gender == 'Dreng') {
               var boysFull = document
                  .getElementById('js-signup')
                  .getAttribute('data-boys-full')

               if (boysFull && boysFull == 'True') {
                  this.registration.IsOnWaitingList = true
               }
            }

            if (this.registration.Gender == 'Pige') {
               var girlsFull = document
                  .getElementById('js-signup')
                  .getAttribute('data-girls-full')

               if (girlsFull && girlsFull == 'True') {
                  this.registration.IsOnWaitingList = true
               }
            }

            Object.keys(this.addons).forEach((key) => {
               const el = document.getElementById(key)
               const price = parseInt(el.getAttribute('data-price'))

               if (this.addons[key]) {
                  this.registration.TotalPrice =
                     this.registration.TotalPrice + price

                  this.registration.Addons.push({
                     Title: el.getAttribute('data-label'),
                     Price: price,
                  })
               }
            })

            this.$validator.validate().then((valid) => {
               if (!valid) {
                  var firstError = document.querySelector('.invalid')
                  var distance =
                     firstError.offsetTop -
                     firstError.scrollTop +
                     firstError.clientTop

                  document.getElementById('js-signup').scrollTo({
                     top: distance - 10,
                     behavior: 'smooth',
                  })
               } else {
                  this.loading = true

                  var xhr = new XMLHttpRequest()

                  xhr.onload = () => {
                     if (xhr.status >= 200 && xhr.status < 300) {
                        this.loading = false
                        this.success = true
                        this.registration = JSON.parse(xhr.response)
                     } else {
                        this.success = false
                        this.error = true
                        this.loading = false
                        this.message = xhr.response
                        console.log('Error')
                     }
                  }

                  xhr.open('POST', '/Umbraco/Api/Camp/SignUp')
                  xhr.setRequestHeader(
                     'Content-Type',
                     'application/json;charset=UTF-8'
                  )
                  xhr.send(JSON.stringify(this.registration))
               }
            })
         },
         openSignUp() {
            this.active = true
            document
               .getElementsByTagName('body')[0]
               .classList.add('overlay-open')
            document
               .getElementsByTagName('html')[0]
               .classList.add('overlay-open')
         },
         closeSignUp() {
            this.active = false
            document
               .getElementsByTagName('body')[0]
               .classList.remove('overlay-open')
            document
               .getElementsByTagName('html')[0]
               .classList.remove('overlay-open')
         },
      },
   })
}
